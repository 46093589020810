import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { makeStyles, Typography, Button } from "@material-ui/core"
import { Form, Field } from "react-final-form"
import emailjs from "emailjs-com"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import PhoneIcon from "@material-ui/icons/Phone"
import minibar from "../../../../images/minibar.png"
import codigoEticaConductaPdf from '../../../../images/archivos/codigoEticaConducta.pdf'

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}))

export const CodigoEticaConducta = () => {
  return (
    <Container id="codigoEticaConducta" className="mb-5">
      <Row className="mt-5 pt-5 text-center">
        <Col>
          <Typography className="dfs-title" paragraph={true}>
          Código de Ética y Conducta
          </Typography>
          <object width="100%" height="750" data={codigoEticaConductaPdf} type="application/pdf">   </object>
        </Col>
      </Row>
    </Container>
  )
}
