import React from 'react'
import { CodigoEticaConducta } from '../Components/Home/Body/CodigoEticaConducta'

export const CodigoEticaConductaPage = () => {
    return (
        <>
            <CodigoEticaConducta/>
        </>
    )
}
